import React from "react"
import CustomHeading from "../../components/heading"
import SectionHeader from "../../components/_layout/section-header"
import { Box } from "rebass"
import Layout from "../../components/_layout"
import Divider from "../../components/divider"
import TeamBlocks from "../team/team-blocks"
import Container from "../../components/_layout/container"
import Pattern from "../../components/pattern"
import { css } from "emotion"
import SEO from "../../components/seo"

const ContactUs = () => {
  const team = [
    {
      id: 1,
      picture:
        "https://spinup-static-website.s3.eu-south-1.amazonaws.com/team/luigi.jpg",
      name: "Luigi De Rosa",
      role: "+39 342 571 5529",
    },
  ]

  return (
    <Layout>
      <SEO
        title="Contact us"
        description="Do you wish to contact us? Find here all the ways to do it!"
      />
      <SectionHeader title="Contact us" />
      <Pattern opacity={0.2} />
      <Box bg="dark" color="white">
        <Divider
          theme="dark"
          firstLine="Hi, nice to meet you: we are Spinup! If you wish to have more info about our services or you want to get in touch with us for any topic, you are in the right place."
          secondLine="How may we help you?"
        />
      </Box>
      <Container size="small">
        <iframe
          title="chatbot"
          className={css`
            width: 100%;
            height: 500px;
          `}
          scrolling="no"
          src="https://chats.spinup.media/c/site-contact-us"
        />
      </Container>
      {/*<CustomHeading*/}
      {/*  py={0}*/}
      {/*  color="text"*/}
      {/*  backgroundColor="background"*/}
      {/*  title="Do you prefer to talk to a human? Contact our sales guys!"*/}
      {/*  subtitle="Our referents"*/}
      {/*/>*/}
      {/*<TeamBlocks team={team} />*/}
    </Layout>
  )
}

export default ContactUs
